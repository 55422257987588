
<template>
  <div class="match_warpper">
    <div class="info">
      <div class="league">
        <div class="gametypeandtime">
          <div class="gtimg">
           <img src="../../../../assets/images/icon/type/1/football-ico.png" v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER" alt="">
            <img src="../../../../assets/images/icon/type/1/baseball-ico.png" v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_BASEBALL" alt="">
            <img src="../../../../assets/images/icon/type/1/basketball-ico.png" v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL" alt="">
            <img src="../../../../assets/images/icon/type/1/volleyball-ico.png" v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL" alt="">
            <img src="../../../../assets/images/icon/type/1/hockey-ico.png" v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_HOCKEY" alt="">
            <img src="../../../../assets/images/icon/type/1/esport-ico.png" v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_ESPORTS" alt="">
            <img src="../../../../assets/images/icon/type/1/tennis-ico.png" v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_TENNIS" alt="">
            <img src="../../../../assets/images/icon/type/1/usa-football-ico.png" v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL" alt="">
          </div>
          <div class="time">
            {{game.startTime|datef('MM월DD일 HH:mm')}}
          </div>
        </div>
        <div class="limg"><img alt="" :src="game.league.imageUrl"></div>
        <div class="lname"  :style="{color:game.league.leagueColor}">{{game.league.leagueName}}</div>
        <div class="gamemore"></div>
      </div>
      <!--승무패-->
      <div class="match" v-if="game.kind === sportsConst.GAME_KIND_1X2">
        <div class="type">승무패</div>
        <div class="home" :class="{'active_sports':game.selectWay === sportsConst.WAY_HOME}"
             @click="selectItem(game,sportsConst.WAY_HOME)">
          <span class="teamname spelingcut" v-html="game.homeTeamName"> </span>
          <span class="homeodd">
            <span class="om">{{game.homeTeamOdds}}</span>
          </span>
        </div>
        <div class="mid draw" v-if="game.kind === sportsConst.GAME_KIND_1X2 && game.drawOdds > 0" :class="{'active_sports':game.selectWay === sportsConst.WAY_DRAW}"
             @click="selectItem(game,sportsConst.WAY_DRAW)">
          {{game.drawOdds}}
        </div>
        <div class="mid draw vs" v-else>vs</div>

        <div class="away" :class="{'active_sports':game.selectWay === sportsConst.WAY_AWAY}"
             @click="selectItem(game,sportsConst.WAY_AWAY)">
          <span class="awayodd">
               <span class="om"> {{game.awayTeamOdds}}</span>
          </span>
          <span class="teamname spelingcut" v-html="game.awayTeamName"></span>
        </div>
      </div>
      <!--핸디캡-->
      <div class="match"  v-if="game.kind === sportsConst.GAME_KIND_HANDICAP" >
        <div class="type">핸디캡</div>
        <div class="home" :class="{'active_sports':game.selectWay === sportsConst.WAY_HOME}"
             @click="selectItem(game,sportsConst.WAY_HOME)">
          <span class="teamname spelingcut" v-html="game.homeTeamName"></span>
          <span class="homeodd">
            <i class="fa fa-h-square hicon lefticon"></i>
            <span class="om">{{game.homeTeamOdds}}</span>
          </span>
        </div>
        <div class="mid draw txthandicap">{{game.handicapVal}}</div>
        <div class="away" :class="{'active_sports':game.selectWay === sportsConst.WAY_AWAY}"
             @click="selectItem(game,sportsConst.WAY_AWAY)">
          <span class="awayodd">
               <span class="om"> {{game.awayTeamOdds}}</span>
              <i class="fa fa-h-square hicon righticon"></i>
          </span>
          <span class="teamname spelingcut" v-html="game.awayTeamName"></span>
        </div>
      </div>

      <!--오버언더-->
      <div class="match"  v-if="game.kind === sportsConst.GAME_KIND_OVERUNDER" >
        <div class="type">오버언더</div>
        <div class="home" :class="{'active_sports':game.selectWay === sportsConst.WAY_HOME}"
             @click="selectItem(game,sportsConst.WAY_HOME)">
          <span class="teamname spelingcut" v-html="game.homeTeamName"></span>
          <span class="homeodd">
               <i class="fa fa-arrow-up ouicon2 lefticon"></i>
            <span class="om">{{game.homeTeamOdds}}</span>
          </span>
        </div>
        <div class="mid draw txtoverunder">{{game.overunderVal}}</div>
        <div class="away" :class="{'active_sports':game.selectWay === sportsConst.WAY_AWAY}"
             @click="selectItem(game,sportsConst.WAY_AWAY)">
          <span class="awayodd">
               <span class="om"> {{game.awayTeamOdds}}</span>
                <i class="fa fa-arrow-down ouicon1 righticon"></i>
          </span>
          <span class="teamname spelingcut" v-html="game.awayTeamName"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sportsConst from "@/common/sportsConst";
import {getSportsMoreGames} from "@/network/sportsRequest";
import {
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
  RECEIVE_SPORTS_CART_ITEM,
  RECEIVE_SPORTS_CART_ITEM_DEL
} from "@/store/mutation-types";
import {mapGetters} from "vuex";
import {postionMixin, sportsMatchMixin, sportsSelectMixin} from "@/common/mixin";
export default {
  name: "SportsMatchSpecialComp",
  mixins:[sportsSelectMixin],
  components: {},
  props: {
    game: {
      type: Object,
      default() {
        return null;
      }
    },
  },
  data() {
    return {
      sportsConst,
    }
  },

  methods:{

  },
  computed: {
    ...mapGetters({
      'cartItems': 'getSportsCartItems',
      'rankConfig': 'getRankConfig',
      'bonusConfig': 'getBonusConfig',
      'crossRuleConfig': 'getCrossRuleConfig',/*
        'getSelectedGameType': 'getSelectedGameType',
        'getSelectedLeagueList': 'getSelectedLeagueList',*/
    })
  },
  created() {

  }
}
</script>


<style scoped>

</style>